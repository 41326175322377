import { useCoreStore } from '../../stores/core';
import { ICommand } from '../contracts/ICommand';

export class SetBackgroundColorCommand implements ICommand {
  constructor(private color: string, private opacity: number = 1) {}

  async execute(): Promise<void> {
    useCoreStore
      .getState()
      .htCoreManager.setBackgroundColor(this.color, this.opacity);
  }
}
