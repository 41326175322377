import { ReactNode, createContext, useCallback, useEffect } from 'react';
import { CommandInvoker } from '../commands/command-invoker';
import { StopCommand } from '../commands/core/stop-command';
import { useModalStore } from '../stores/modal';

type CoreStopContextType = {
  stopCoreActions: () => void;
};

const CoreStopContext = createContext<CoreStopContextType | undefined>(
  undefined
);

export const CoreStopProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const isOpen = useModalStore((state) => state.isOpen);

  const stopCoreActions = useCallback(() => {
    const commandInvoker = new CommandInvoker();
    commandInvoker.addCommand(new StopCommand());
    commandInvoker.executeCommands();
  }, []);

  useEffect(() => {
    stopCoreActions();
  }, [isOpen, stopCoreActions]);

  return (
    <CoreStopContext.Provider value={{ stopCoreActions }}>
      {children}
    </CoreStopContext.Provider>
  );
};
