import { useCallback } from 'react';
import { CommandInvoker } from '../../commands/command-invoker';
import { DisplaySubtitleCommand } from '../../commands/core/display-subtitle-command';
import { RepeatCommand } from '../../commands/core/repeat-command';
import { SignAnimationCodeCommand } from '../../commands/core/sign-animation-codes-command';
import { hideHTMLElement } from '../../shared/utils/adapters';
import { useCoreSentenceStore } from '../../stores/core-sentence';
import { useModalStore } from '../../stores/modal';
import { useButtonReferenceStore } from '../../stores/repeat-button';

export const useRepeatCommand = () => {
  const [sentence, sentenceAnimation] = useCoreSentenceStore((state) => [
    state.sentence,
    state.sentenceAnimation,
  ]);
  const isOpen = useModalStore((state) => state.isOpen);
  const repeatButtonRef = useButtonReferenceStore(
    (state) => state.repeatButtonRef
  );

  const createCommands = useCallback(() => {
    const commandInvoker = new CommandInvoker();

    if (!isOpen) {
      commandInvoker.addCommand(
        new SignAnimationCodeCommand(sentenceAnimation)
      );
      commandInvoker.addCommand(new DisplaySubtitleCommand(sentence));
    } else {
      commandInvoker.addCommand(new RepeatCommand());
    }

    return commandInvoker;
  }, [isOpen, sentence, sentenceAnimation]);

  const executeRepeatCommand = useCallback(() => {
    hideHTMLElement(repeatButtonRef.current!, true);

    const commandInvoker = createCommands();
    commandInvoker.executeCommands();
  }, [repeatButtonRef, createCommands]);

  return executeRepeatCommand;
};
