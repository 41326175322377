import { Avatar } from '../../shared/types/avatar/types';
import { hideHTMLElement } from '../../shared/utils/adapters';
import { useCoreStore } from '../../stores/core';
import { useButtonReferenceStore } from '../../stores/repeat-button';
import { ISignCallbacks } from '../contracts/ISignCallbacks';

declare global {
  interface Window {
    HTCore: any;
  }
}

export class HTCoreManager {
  private coreInstance: any = null;

  private getRepeatButtonRef(): HTMLElement | null {
    return useButtonReferenceStore.getState().repeatButtonRef.current;
  }

  async initializeCore(
    avatarHolder: HTMLElement,
    avatar: Avatar
  ): Promise<void> {
    const core = new window.HTCore({
      parentElement: avatarHolder,
      avatar: avatar.toUpperCase(),
      width: 1600,
      height: 1600,
    });

    core.setSubtitleOptions({
      color: '#000000',
      'font-family': 'Lato',
      'font-size': '1.5rem',
    });

    this.coreInstance = core;

    return new Promise((resolve, reject) => {
      core
        .load(true)
        .then(() => {
          useCoreStore.getState().setInitialized(true);
          resolve();
        })
        .catch(reject);
    });
  }

  setBackgroundColor(color: string, opacity: number = 1) {
    this.coreInstance.setBackgroundColor(color, opacity);
  }

  displaySubtitle(sentence: string) {
    if (!this.coreInstance) {
      console.error('Core instance is not initialized');
      return;
    }
    this.coreInstance.displaySubtitle(sentence);

    const coreRelativeElement = document.getElementsByClassName(
      'core-relative'
    )[0] as HTMLElement;
    coreRelativeElement.style.overflow = 'hidden';
  }

  signAnimationCode(animations: string, callbacks: ISignCallbacks = {}) {
    const animationCodesArray = animations.split('$').concat('@repouso');
    const repeatButton = this.getRepeatButtonRef();

    if (repeatButton) {
      hideHTMLElement(repeatButton, true);
    }

    this.coreInstance.signAnimationCodes(animationCodesArray, true, true, {
      onSignalized: () => {
        const repeatButton = this.getRepeatButtonRef();
        if (repeatButton) {
          hideHTMLElement(repeatButton, false);
        }
        callbacks.onSignalized?.();
      },
      onDownloaded: callbacks.onDownloaded,
      onCanceled: callbacks.onCanceled,
      onError: callbacks.onError,
    });
  }

  stop() {
    const repeatButton = this.getRepeatButtonRef();
    hideHTMLElement(repeatButton!, false);
    this.coreInstance.stop();
  }

  repeat() {
    const repeatButton = this.getRepeatButtonRef();

    if (repeatButton) {
      hideHTMLElement(repeatButton, true);
    }

    if (!this.coreInstance) {
      console.error('Core instance is not initialized');
      return;
    }

    this.coreInstance.repeat({
      onSignalized: () => {
        const repeatButton = this.getRepeatButtonRef();
        if (repeatButton) {
          hideHTMLElement(repeatButton, false);
        }
      },
    });
  }
}
