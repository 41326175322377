import { IAnalytics } from '../services/contracts/IAnalytics';

export class LogEventUseCase {
  constructor(private analyticsRepository: IAnalytics) {
    this.analyticsRepository = analyticsRepository;
  }

  execute(eventName: string, eventParams?: Record<string, any>): void {
    this.analyticsRepository.logEvent(eventName, eventParams);
  }
}
