import { create } from 'zustand';
import { HTCoreManager } from '../services/implementations/HTCoreManager';

interface ICoreStoreState {
  htCoreManager: HTCoreManager;
  setHtCoreManager: (manager: HTCoreManager) => void;
  initialized: boolean;
  setInitialized: (initialized: boolean) => void;
}

export const useCoreStore = create<ICoreStoreState>((set) => ({
  htCoreManager: new HTCoreManager(),
  setHtCoreManager: (manager: HTCoreManager) => set({ htCoreManager: manager }),
  initialized: false,
  setInitialized: (initialized: boolean) => set({ initialized }),
}));
