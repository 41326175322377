import classNames from 'classnames';
import React, { useCallback } from 'react';
import { ETranslationEvents } from '../../shared/enums/ETranslationEvents';
import { useExpirationStore } from '../../stores/expiration';
import { useModalStore } from '../../stores/modal';
import { logEventFactory } from '../../usecases/factories/log-event-factory';
import { ModalHeader } from './ModalHeader';
import './styles.scss';

interface ModalContentProps {
  children: React.ReactNode;
}

export const ModalContent: React.FC<ModalContentProps> = ({ children }) => {
  const [close, isOpen] = useModalStore((state) => [state.close, state.isOpen]);
  const expired = useExpirationStore((state) => state.expired);

  const onClose = useCallback(() => {
    logEventFactory.execute(ETranslationEvents.CloseTranslationText);
    close();
  }, [close]);

  return (
    <div
      className={classNames('modal-content', {
        open: isOpen,
        closed: !isOpen,
        expired,
      })}
    >
      {isOpen && <ModalHeader onClose={onClose} />}
      {children}
    </div>
  );
};
