import { create } from 'zustand';

interface IModalState {
  isOpen: boolean;
  content: React.ReactNode;
  open: (content: React.ReactNode) => void;
  close: () => void;
}

export const useModalStore = create<IModalState>((set) => ({
  isOpen: false,
  content: null,
  open: (content: React.ReactNode) => set({ isOpen: true, content }),
  close: () => set({ isOpen: false, content: null }),
}));
