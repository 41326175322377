import { ICommand } from './contracts/ICommand';

export class CommandInvoker {
  private commands: ICommand[] = [];

  addCommand(command: ICommand): void {
    this.commands.push(command);
  }

  async executeCommands(): Promise<void> {
    for (const command of this.commands) {
      await command.execute();
    }
    this.commands = [];
  }
}
