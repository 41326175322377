import { ISignCallbacks } from '../../services/contracts/ISignCallbacks';
import { useCoreStore } from '../../stores/core';
import { ICommand } from '../contracts/ICommand';

export class SignAnimationCodeCommand implements ICommand {
  constructor(
    private animations: string,
    private callbacks: ISignCallbacks = {}
  ) {}

  async execute(): Promise<void> {
    useCoreStore
      .getState()
      .htCoreManager.signAnimationCode(this.animations, this.callbacks);
  }
}
