import AppStoreBadgeImage from '../../images/app-store-badge.svg';
import GoogleBadgeImage from '../../images/google-play-badge.svg';
import { IAnalytics } from '../../services/contracts/IAnalytics';
import { ETranslationEvents } from '../../shared/enums/ETranslationEvents';
import { logEventFactory } from '../../usecases/factories/log-event-factory';
import './styles.scss';

const GooglePlayBadge = (analytics: IAnalytics) => (
  <a
    target="_blank"
    className="google-play-badge"
    href="https://play.google.com/store/apps/details?id=br.com.handtalk&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    rel="noreferrer"
    onClick={() =>
      analytics.logEvent(ETranslationEvents.GooglePlayDownloadButton)
    }
  >
    <img alt="Disponível no Google Play" src={GoogleBadgeImage} />
  </a>
);

const AppStoreBadge = (analytics: IAnalytics) => (
  <a
    target="_blank"
    className="app-store-badge"
    href="https://apps.apple.com/us/app/hand-talk/id659816995?itsct=apps_box_badge&amp;itscg=30200"
    rel="noreferrer"
    onClick={() =>
      analytics.logEvent(ETranslationEvents.AppStoreDownloadButton)
    }
  >
    <img alt="Download on the App Store" src={AppStoreBadgeImage} />
  </a>
);

const TranslationLinksFooter = () => {
  return (
    <footer className="footer">
      <div className="content">
        <div>
          <AppStoreBadge logEvent={logEventFactory.execute} />
          <GooglePlayBadge logEvent={logEventFactory.execute} />
        </div>
      </div>
    </footer>
  );
};

export default TranslationLinksFooter;
