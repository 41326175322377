import HandTalkLogo from '../../images/logo.svg';
import { ETranslationEvents } from '../../shared/enums/ETranslationEvents';
import { logEventFactory } from '../../usecases/factories/log-event-factory';
import './styles.scss';

const TranslationLinksHeader = () => {
  return (
    <header
      className="header"
      onClick={() => {
        logEventFactory.execute(ETranslationEvents.ClickLogoHT);
        window.open('https://www.handtalk.me/br/', '_blank');
      }}
    >
      <img src={HandTalkLogo} alt="hand talk logo" />
    </header>
  );
};

export default TranslationLinksHeader;
