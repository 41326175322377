import React from 'react';
import { CommandInvoker } from '../../commands/command-invoker';
import { SignAnimationCodeCommand } from '../../commands/core/sign-animation-codes-command';
import { StopCommand } from '../../commands/core/stop-command';
import SignLanguageIcon from '../../images/icons/repeat_white_24dp.svg';
import { appInfoAnimations } from '../../shared/animation-codes/download-hand-talk-app';
import { ETranslationEvents } from '../../shared/enums/ETranslationEvents';
import {
  getLocalizedString,
  getNavigatorLangOrEquivalent,
} from '../../shared/localization';
import { useModalStore } from '../../stores/modal';
import { logEventFactory } from '../../usecases/factories/log-event-factory';
import './styles.scss';

type AppInfoButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const AppInfoButton: React.FC<AppInfoButtonProps> = (props) => {
  const open = useModalStore((state) => state.open);
  const executeAppInfoAnimationCodes = () => {
    logEventFactory.execute(ETranslationEvents.ViewTranslationText);
    open(
      <p className="app-info-text">
        {getLocalizedString('download_app', 'en')}
      </p>
    );
    const lang = getNavigatorLangOrEquivalent('en');
    const commandInvoker = new CommandInvoker();

    commandInvoker.addCommand(new StopCommand());
    commandInvoker.addCommand(
      new SignAnimationCodeCommand(appInfoAnimations[lang])
    );

    commandInvoker.executeCommands();
  };
  return (
    <div className="app-info-button-container">
      <button
        {...props}
        className="app-info-translation-button"
        onClick={executeAppInfoAnimationCodes}
      >
        <img src={SignLanguageIcon} alt="ícone de libras" />
      </button>
      {props.children}
    </div>
  );
};

export default AppInfoButton;
