import React from "react";
import HandTalkLoader from "../HandTalkLoader";

const LoaderContainer: React.FC = () => {
  return (
    <div className="handtalk-loader-container">
      <HandTalkLoader />
    </div>
  );
};

export default LoaderContainer;
