import RepeatIcon from '../../images/repeat.svg';
import { ETranslationEvents } from '../../shared/enums/ETranslationEvents';
import { logEventFactory } from '../../usecases/factories/log-event-factory';
import './styles.scss';
import { useRepeatCommand } from './use-repeat-command';

export const RepeatButton = () => {
  const executeRepeatCommand = useRepeatCommand();
  return (
    <button
      id="repeat-translation-button"
      onClick={() => {
        logEventFactory.execute(ETranslationEvents.WebRepeatTranslation);
        executeRepeatCommand();
      }}
    >
      <img src={RepeatIcon} alt="repeat icon" />
    </button>
  );
};
