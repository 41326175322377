import React, { createRef } from 'react';
import { create } from 'zustand';

type RepeatButtonRefProps = HTMLElement | null;

interface IButtonReferenceStoreState {
  repeatButtonRef: React.MutableRefObject<RepeatButtonRefProps>;
  setRepeatButtonRef: (ref: React.RefObject<RepeatButtonRefProps>) => void;
}

const repeatButtonRef =
  createRef<RepeatButtonRefProps>() as React.MutableRefObject<RepeatButtonRefProps>;
repeatButtonRef.current = document.getElementById('repeat-translation-button');

export const useButtonReferenceStore = create<IButtonReferenceStoreState>(
  (set) => ({
    repeatButtonRef,
    setRepeatButtonRef: (ref: React.MutableRefObject<RepeatButtonRefProps>) =>
      set({ repeatButtonRef: ref }),
  })
);
