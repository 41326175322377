export const localizedStrings = {
  pt: {
    download_for_free: 'Baixe grátis',
    terms_of_use: 'Termos e condições',
    terms_of_use_uri:
      'https://suporte.handtalk.me/kb/article/240749/termos-de-uso-do-aplicativo-hand-talk?menuId=25979-69987-240749&ticketId=&q=',
    download_app:
      'Baixe o Hand Talk App para traduzir e aprender Línguas de Sinais de forma divertida e gratuita!',
    no_longer_available_content_title: 'Ops! Este link expirou.',
    no_longer_available_content_message:
      'Você pode continuar traduzindo texto e áudio para Língua de Sinais com o nosso app.',
  },
  en: {
    download_for_free: 'Free download',
    terms_of_use: 'Terms of use',
    terms_of_use_uri:
      'https://suporte.handtalk.me/kb/article/240750/terms-and-conditions-hand-talk-app?menuId=26741-72300-240750&ticketId=&q=',
    download_app:
      'Download the Hand Talk App to translate and learn Sign Languages in a fun and free way!',
    no_longer_available_content_title: 'Oops! This link has expired.',
    no_longer_available_content_message:
      'You can still translate text and audio to Sign Languages using our app.',
  },
};
