import React, { useCallback, useEffect, useRef } from 'react';
import '../../shared/styles.scss';
import { useCoreStore } from '../../stores/core';
import { useExpirationStore } from '../../stores/expiration';
import { useModalStore } from '../../stores/modal';
import { useButtonReferenceStore } from '../../stores/repeat-button';
import { Modal } from '../Modal';
import { ModalContent } from '../Modal/ModalContent';
import { RepeatButton } from '../RepeatButton';
import TranslationLinksHeader from '../TranslationLinksHeader';

interface AvatarHolderProps {
  children: React.ReactNode;
}

export const AvatarHolder: React.FC<AvatarHolderProps> = ({ children }) => {
  const repeatButtonRef = useRef<HTMLElement | null>(null);
  const avatarCanvasContainerRef = useRef<HTMLDivElement | null>(null);
  const canvasElement = document.getElementsByTagName('canvas');

  const [isOpen, content] = useModalStore((state) => [
    state.isOpen,
    state.content,
  ]);
  const expired = useExpirationStore((state) => state.expired);
  const initialized = useCoreStore((state) => state.initialized);

  const setRepeatButtonRef = useButtonReferenceStore(
    (state) => state.setRepeatButtonRef
  );

  const setupRepeatButton = useCallback(() => {
    const repeatButton = document.getElementById('repeat-translation-button');
    const avatarCanvasContainer = document.getElementById(
      'avatar-canvas-container'
    ) as HTMLDivElement;

    if (repeatButton && avatarCanvasContainer) {
      repeatButtonRef.current = repeatButton;
      avatarCanvasContainer.appendChild(repeatButtonRef.current);
      setRepeatButtonRef(repeatButtonRef);
    }
  }, [setRepeatButtonRef]);

  const handleExpirationStyles = useCallback(() => {
    if (!expired) {
      return;
    }
    const repeatTranslationButton = document.getElementById(
      'repeat-translation-button'
    );
    if (repeatTranslationButton) {
      repeatTranslationButton.style.display = 'none';
    }
  }, [expired]);

  const handleCanvasStyles = useCallback(() => {
    if (expired) {
      canvasElement[0].style.borderRadius = '100%';
    } else if (isOpen) {
      canvasElement[0].style.borderRadius = '0px';
    } else {
      canvasElement[0].style.borderRadius = '8px';
    }
  }, [canvasElement, expired, isOpen]);

  useEffect(() => {
    setupRepeatButton();
  }, [setupRepeatButton]);

  useEffect(() => {
    handleExpirationStyles();
  }, [handleExpirationStyles]);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    handleCanvasStyles();
  }, [handleCanvasStyles, initialized]);

  return (
    <Modal>
      {!isOpen && <TranslationLinksHeader />}
      <ModalContent>
        <div
          id="avatar-holder"
          style={
            isOpen
              ? {
                  padding: 0,
                  height: 'inherit',
                }
              : {
                  height: '100%',
                }
          }
        >
          {!isOpen && children}
          <div id="avatar-canvas-container" ref={avatarCanvasContainerRef} />
          <RepeatButton />
        </div>
        {isOpen && content}
      </ModalContent>
    </Modal>
  );
};
