import React from 'react';
import './styles.scss';

interface ModalHeaderProps {
  onClose: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ onClose }) => {
  return (
    <div className="modal-header">
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};
