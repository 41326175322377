import { ApiAnimationCodeReturn } from '../shared/types/avatar/types';
import { type IHttpClient } from './contracts/IHttpClient';

const apiUrl = 'https://translation-v3.handtalk.me/api';
const apiKey = '6c98cd3ab86b5548766a03f27855124b';

export const fetchAnimationCode = async (
  client: IHttpClient,
  token: string
): Promise<ApiAnimationCodeReturn> => {
  const endpoint = apiUrl + '/getHashAnimations';
  const headers = {
    Accept: 'application/json',
    key: apiKey,
  };
  const body = {
    hash: token,
  };
  return client.post<ApiAnimationCodeReturn>(endpoint, body, headers);
};
