import React from 'react';
import { getLocalizedString } from '../../shared/localization';
import AppInfoButton from '../AppInfoButton';

const AvatarContainer: React.FC = () => {
  return (
    <AppInfoButton id="app-info-button">
      {getLocalizedString('download_app', 'en')}
    </AppInfoButton>
  );
};

export default AvatarContainer;
