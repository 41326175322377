import { create } from 'zustand';

interface IExpirationStoreState {
  expired: boolean;
  setExpired: (expired: boolean) => void;
}

export const useExpirationStore = create<IExpirationStoreState>((set) => ({
  expired: false,
  setExpired: (expired) => set({ expired }),
}));
