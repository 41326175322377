import 'dotenv/config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AvatarHolder } from './components/AvatarHolder';
import LoaderContainer from './components/LoaderContainer';
import { TranslationLinksBody } from './components/TranslationLinksBody';
import TranslationLinksFooter from './components/TranslationLinksFooter';
import { useTranslationLinks } from './hooks/useTranslationLinks';
import { CoreStopProvider } from './providers/core-stop-provider';
import { FetchHttpClient } from './services/implementations/FetchHttpClient';
import './styles.scss';

const App: React.FC = () => {
  const httpClient = new FetchHttpClient();
  const translationLinksResponse = useTranslationLinks(httpClient);

  return (
    <div className="App">
      <CoreStopProvider>
        <AvatarHolder>
          {translationLinksResponse.isPending ? (
            <LoaderContainer />
          ) : (
            <>
              <TranslationLinksFooter />
              <TranslationLinksBody {...translationLinksResponse} />
            </>
          )}
        </AvatarHolder>
      </CoreStopProvider>
    </div>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
