import { Avatar } from '../../shared/types/avatar/types';
import { useCoreStore } from '../../stores/core';
import { ICommand } from '../contracts/ICommand';

export class InitializeCoreCommand implements ICommand {
  constructor(private avatarHolder: HTMLElement, private avatar: Avatar) {}

  async execute(): Promise<void> {
    await useCoreStore
      .getState()
      .htCoreManager.initializeCore(this.avatarHolder, this.avatar);
  }
}
