import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useModalStore } from '../../stores/modal';
import './styles.scss';

interface ModalProps {
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ children }) => {
  const [isOpen, close] = useModalStore((state) => [state.isOpen, state.close]);

  const handleClickOutside = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        close();
      }
    },
    [close]
  );

  return (
    <div
      className={classNames('modal-container', { open: isOpen })}
      onClick={handleClickOutside}
    >
      {children}
    </div>
  );
};
