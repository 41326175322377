import { ITranslationLinksProps } from '../../shared/types/translationLinks/types';
import AvatarContainer from '../AvatarContainer';
import ExpiredLinkInfo from '../ExpiredLinkInfo';

export const TranslationLinksBody: React.FC<ITranslationLinksProps> = ({
  expired,
}) => {
  if (expired) {
    return <ExpiredLinkInfo />;
  }
  return <AvatarContainer />;
};
