import { IAnalytics } from '../contracts/IAnalytics';
import { logEvent as _logEvent, Analytics } from 'firebase/analytics';

export class FirebaseAnalyticsRepository implements IAnalytics {
  // constructor(private instance: Analytics) { }

  logEvent(
    eventName: string,
    eventParams?: Record<string, any> | undefined
  ): void {
    // _logEvent(this.instance, eventName, eventParams);
  }
}
