import { create } from 'zustand';

interface ICoreSentenceStoreState {
  sentence: string;
  setSentence: (sentence: string) => void;
  sentenceAnimation: string;
  setSentenceAnimation: (sentence: string) => void;
}

export const useCoreSentenceStore = create<ICoreSentenceStoreState>((set) => ({
  sentence: '',
  setSentence: (sentence: string) => set({ sentence }),
  sentenceAnimation: '',
  setSentenceAnimation: (sentenceAnimation: string) =>
    set({ sentenceAnimation }),
}));
