import { localizedStrings } from './strings';
import { Languages, StringsKeys } from './types';

declare global {
  interface Navigator {
    userLanguage: string;
  }
}

export const getNavigatorLangOrEquivalent = (
  fallbackLanguage: Languages
): Languages => {
  let userLanguage = navigator.language || navigator.userLanguage;
  let languageCodes = Object.keys(localizedStrings);

  var code;
  for (let index in languageCodes) {
    code = languageCodes[index];
    if (userLanguage.match(code)) {
      return code as Languages;
    }
  }

  return fallbackLanguage;
};

export const getLocalizedString = (
  key: StringsKeys,
  fallbackLanguage: Languages
): string => {
  let locale = getNavigatorLangOrEquivalent(fallbackLanguage);
  let localizedString: string;

  try {
    localizedString = localizedStrings[locale][key];
  } catch (error) {
    console.warn(
      `TypeError: navigator.language não reconhecido, utilizando fallback...`
    );
    localizedString = localizedStrings[fallbackLanguage][key];
  }

  return localizedString;
};
