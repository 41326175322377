import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchAnimationCode } from '../services/api';
import { type IHttpClient } from '../services/contracts/IHttpClient';
import { CommandInvoker } from '../commands/command-invoker';
import { InitializeCoreCommand } from '../commands/core/initialize-core-command';
import { SignAnimationCodeCommand } from '../commands/core/sign-animation-codes-command';
import { DisplaySubtitleCommand } from '../commands/core/display-subtitle-command';
import { SetBackgroundColorCommand } from '../commands/core/set-background-color-command';
import { getTokenParameter } from '../shared/utils/get-token';
import { Avatar } from '../shared/types/avatar/types';
import { useCoreSentenceStore } from '../stores/core-sentence';
import { useExpirationStore } from '../stores/expiration';

export const useTranslationLinks = (httpClient: IHttpClient) => {
  const [isPending, setIsPending] = useState(true);

  const [expired, setExpired] = useExpirationStore((state) => [
    state.expired,
    state.setExpired,
  ]);
  const [setHashSentence, setSentenceAnimation] = useCoreSentenceStore(
    (state) => [state.setSentence, state.setSentenceAnimation]
  );

  const commandInvoker = useRef(new CommandInvoker()).current;

  const initializeCore = useCallback(
    async (avatar: Avatar) => {
      const avatarHolder = document.getElementById('avatar-holder');
      if (avatarHolder) {
        commandInvoker.addCommand(
          new InitializeCoreCommand(avatarHolder, avatar)
        );
        commandInvoker.addCommand(new SetBackgroundColorCommand('#DEDEDE'));
      }
    },
    [commandInvoker]
  );

  const verifyHashExpirationAndSign = useCallback(async () => {
    const tokenParam = getTokenParameter();
    try {
      const res = await fetchAnimationCode(httpClient, tokenParam ?? '');
      const { status, msg, animations, sentence, avatar = 'HUGO' } = res;
      if (status && status !== 200) {
        console.error(`Falha na requisição: Código (${status})`, msg);
        setExpired(true);
        return;
      }

      setSentenceAnimation(animations);
      setHashSentence(sentence);

      await initializeCore(avatar);
      commandInvoker.addCommand(new SignAnimationCodeCommand(animations));
      commandInvoker.addCommand(new DisplaySubtitleCommand(sentence));
    } catch (err) {
      await initializeCore('HUGO');
      setExpired(true);
    } finally {
      setIsPending(false);
      commandInvoker.executeCommands();
    }
  }, [
    commandInvoker,
    httpClient,
    initializeCore,
    setExpired,
    setHashSentence,
    setSentenceAnimation,
  ]);

  useEffect(() => {
    verifyHashExpirationAndSign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    expired,
    isPending,
  };
};
