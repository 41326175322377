import { useCallback, useEffect } from 'react';
import { CommandInvoker } from '../../commands/command-invoker';
import { SignAnimationCodeCommand } from '../../commands/core/sign-animation-codes-command';
import { appInfoAnimations } from '../../shared/animation-codes/download-hand-talk-app';
import {
  getLocalizedString,
  getNavigatorLangOrEquivalent,
} from '../../shared/localization';
import { useCoreStore } from '../../stores/core';
import { expirationPageTitleAnimationCodes } from './animations';
import './styles.scss';

const ExpiredLinkInfo = () => {
  const initialized = useCoreStore((state) => state.initialized);
  const executeExpiredInformationAnimations = useCallback(() => {
    const commandInvoker = new CommandInvoker();
    const lang = getNavigatorLangOrEquivalent('en');
    commandInvoker.addCommand(
      new SignAnimationCodeCommand(expirationPageTitleAnimationCodes[lang], {
        onSignalized: () =>
          new SignAnimationCodeCommand(appInfoAnimations[lang], {
            onSignalized: executeExpiredInformationAnimations,
          }).execute(),
      })
    );
    commandInvoker.executeCommands();
  }, []);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    executeExpiredInformationAnimations();
  }, [executeExpiredInformationAnimations, initialized]);

  return (
    <div className="expired-link-info-holder">
      <div className="expired-link-info-container">
        <h2>{getLocalizedString('no_longer_available_content_title', 'en')}</h2>
        <p>{getLocalizedString('download_app', 'en')}</p>
      </div>
    </div>
  );
};

export default ExpiredLinkInfo;
